import * as Yup from "yup";

export const signupSchema = Yup.object({
  name: Yup.string().min(2, "Name must be greater than 2 character").max(50, "Name must be less than 50 character").required("Name is required!"),
  mobile: Yup.string()
    .matches(/^[6789]\d{9}$/, "Please Enter a valid mobile number")
    .required("Mobile number is required!"),
  email: Yup.string()
    .matches(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, "Please enter a valid Email")
    .required("Email is required!"),
  password: Yup.string().required("Password is required!"),
  address: Yup.string().required("Address is required!"),
  stateName: Yup.string().required("State is required"),
  districtName: Yup.string().required("District is required").notOneOf(["Select District"]),
  pincode: Yup.number().min(100000, "Please enter a valid 6-digit pin code!").max(999999, "Please enter a valid 6-digit pin code!").required("Pin code is required!"),
  natureOfBusiness: Yup.object().required("Nature of business is required!"),
  areaOfOperation: Yup.array().required("Area of operation is required!"),
  yearsOfExperience: Yup.number().positive("Please enter a valid year").max(50, "Experience cannot be greater than 50").required("Experience is required!"),
  companyName: Yup.array().min(1, "Please select company name"),
  otherCompanyName: Yup.string().when(["companyName"], (companyName, schema) => {
    return companyName && companyName[0].some((company: any) => company.name === "Other") ? schema.required("Please provide other company name") : schema;
  })
});
