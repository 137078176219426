import * as Yup from "yup";

export const editUserSchema = Yup.object({
  name: Yup.string()
    .min(2, "Name must be greater than 2 character")
    .max(50, "Name must be less than 50 character")
    .trim()
    .test("is-not-empty-or-space", "Name cannot be empty or spaces only", (value: any) => value && value.trim().length > 0)
    .required("Name is required!"),
  mobile: Yup.string()
    .matches(/^[6789]\d{9}$/, "Please Enter a valid mobile number")
    .required("Mobile number is required!"),
  email: Yup.string()
    .trim()
    .matches(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, "Please enter a valid Email")
    .required("Email is required!"),
  dob: Yup.date().max(new Date(), "Date of Birth cannot be in the future"),
  gender: Yup.string(),
  aadhaarNumber: Yup.string().length(12, "Aadhar Number must be of exactly 12 digits"),

  natureOfBusiness: Yup.string(),
  areaOfOperation: Yup.array(),
  panNumber: Yup.string()
    .length(10, "Pan Number must be of exactly 10 digits")
    .matches(/^[a-zA-Z]{5}\d{4}[a-zA-Z]$/, "Please Enter a valid pan number!"),
  gstNumber: Yup.string().matches(/^\d{2}[a-zA-Z]{5}\d{4}[a-zA-Z]\d[a-zA-Z]\d$/, "Please Enter a valid gst Number!"),
  companyName: Yup.string().matches(/[^\s\W]/g, "Please Enter a valid comapny name !"),
  about: Yup.string().matches(/[^\s\W]/g, "Please Enter a valid data !"),
  website: Yup.string().matches(/^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:[0-9]{1,5})?(\/.*)?$/, "Please Enter a valid Web URL !")
});
