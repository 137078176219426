import React, { FC, useState } from "react";
import Modal from "component/common/Modal";
import CloseIcon from "assets/icons/gallery/close.svg";
import Typography from "component/semantic/Typography";
import CustomInput from "component/semantic/Input";
import ButtonOutline from "component/semantic/Button";
import FileUpload from "component/common/FileUpload";
import { FaFile } from "react-icons/fa";
import { useAppDispatch } from "hooks/redux_toolkit";
import { removeLeadDocThunk, uploadLeadDocThunk } from "store/thunk/NewCase.thunk";
import { getSingleLeadInfo } from "store/thunk/AllLead.thunk";
import { toast } from "react-toastify";
import { stagingUrl } from "helpers/constants/defaultUrls";
import deleteDoc from "assets/icons/newCase/deleteDoc.svg";
import Spinner from "component/common/Spinner";
import { ImSpinner10 } from "react-icons/im";

interface ModalProps {
  showOtherDocModal: boolean;
  setShowOtherDocModal: any;
  selectedLead: any;
  setSelectedLead: any;
}

const OtherDocUpload: FC<ModalProps> = ({ showOtherDocModal, setShowOtherDocModal, selectedLead, setSelectedLead }) => {
  const [formValue, setFormValue] = useState<{ fileName: string; file: any }>({ fileName: "", file: "" });
  const [errorValue, setErrorValue] = useState(false);

  const [loading, setShowLoading] = useState({ upload: false, delete: "" });

  const dispatch = useAppDispatch();

  const submitHandler = () => {
    if (!formValue.fileName?.trim()) setErrorValue(true);
    else setErrorValue(false);
    setShowLoading((prev) => ({ ...prev, upload: true }));

    const updatedFileName = fileNameHandler();

    const formData = new FormData();
    formData.append(formValue.fileName, formValue.file, updatedFileName);
    formData.append("docCreationSrc", "partner-web");
    dispatch(uploadLeadDocThunk({ leadId: selectedLead?._id, formData }))
      .unwrap()
      .then((data: any) => {
        if (data.status == 200) {
          dispatch(getSingleLeadInfo(selectedLead._id))
            .unwrap()
            .then((data) => {
              if (data?.data?.success) {
                setSelectedLead(data?.data?.data);
                setFormValue({ fileName: "", file: "" });
                setShowOtherDocModal(false);
              }
            });
          toast.success(data.msg);
        }
        setShowLoading((prev) => ({ ...prev, upload: false }));
      });
  };

  const fileNameHandler = () => {
    let temp;
    switch (selectedLead.policyTypeId?._id) {
      case "864a375b-5fa7-49c7-9f39-2b1fda4674b5":
        temp = "LifeIns_" + formValue.fileName;
        break;
      case "3030b68f-82e9-4dfb-b2b8-82b743d0692a":
        temp = "healthotherdocs_" + formValue.fileName;
        break;
      case "2de58f71-ab0a-446f-b5f6-b6e0fa6f46f2":
        temp = "generalotherdocs_" + formValue.fileName;
        break;
    }
    return temp + "_" + new Date().getTime();
  };
  let url: string;
  if (process.env.REACT_APP_ENV !== "prod") url = stagingUrl + "lead_docs/" + selectedLead?._id;
  else url = "https://insurance-samadhan-docs.s3.ap-south-1.amazonaws.com/upload/" + "lead_docs/" + selectedLead?._id;

  const removeFileHandler = (name: string) => {
    setShowLoading((prev) => ({ ...prev, delete: name }));
    dispatch(removeLeadDocThunk({ id: selectedLead?._id, file: name }))
      .unwrap()
      .then((data: any) => {
        if (data?.success) {
          if (data.status == 200) {
            dispatch(getSingleLeadInfo(selectedLead._id))
              .unwrap()
              .then((data) => {
                if (data?.data?.success) {
                  setSelectedLead(data?.data?.data);
                }
              });
            toast.success(data?.message);
          }
        }
        setShowLoading((prev) => ({ ...prev, delete: "" }));
      });
  };

  return (
    <>
      {selectedLead?.doc?.some((obj: any) => /(LifeIns_|healthotherdocs_|generalotherdocs_)/.test(obj)) && <Typography className="mb-2">Other Documents</Typography>}
      {selectedLead?.doc &&
        selectedLead?.doc.map((obj: any, index: number) => {
          if (!/(LifeIns_|healthotherdocs_|generalotherdocs_)/.test(obj)) return;
          return (
            <>
              <span className="items-center flex" key={index}>
                <div onClick={() => window.open(url + "/" + obj)} className="text-gray-700 bg-uploadTheme mt-3 cursor-pointer flex px-4 rounded-lg py-1 gap-1 items-center">
                  <FaFile className="text-gray-400 opacity-50 " />
                  <span className="truncate w-48">{obj}</span>
                </div>
                {loading.delete === obj ? <Spinner className="text-xl -mt-3 ml-2" /> : <img src={deleteDoc} onClick={() => removeFileHandler(obj)} className="ml-2 cursor-pointer" />}
              </span>
            </>
          );
        })}
      <Modal showModal={showOtherDocModal} onClose={() => setShowOtherDocModal(false)} className="relative w-[25%] p-8">
        <img src={CloseIcon} className="absolute text-3xl text-gray-400 -right-3 -top-3 cursor-pointer" onClick={() => setShowOtherDocModal(false)} />
        <Typography variant="h1">Other Documents</Typography>
        <div className="my-5 flex flex-col gap-5 w-full">
          <span className="w-full">
            <CustomInput className="h-40 w-full" label="Document Name" placeholder="Enter the document name" value={formValue.fileName} onChange={(e: any) => setFormValue((prev) => ({ ...prev, fileName: e.target.value }))} />
            {errorValue ? <span className="text-xs text-red-600">Document Name is required</span> : ""}
          </span>
          <span className="w-full">
            <FileUpload setSelectedFile={(file: any) => setFormValue((prev) => ({ ...prev, file: file }))} className="px-10 w-full" loading={loading.upload} />
            {formValue.file && (
              <div className="text-gray-700 bg-uploadTheme mt-3 flex px-4 rounded-lg py-1 gap-1 items-center">
                <FaFile className="text-gray-400 opacity-50 " />
                <span className="truncate">{formValue.file?.name}</span>
              </div>
            )}
          </span>
        </div>
        <ButtonOutline className={`bg-polyfixTheme w-full ${loading.upload && "opacity-50 pointer-events-none"}`} title={loading.upload ? "Uploading" : "Upload Document"} type="submit" onClick={submitHandler} icon={loading.upload && <ImSpinner10 className="animate-spin" />} />
      </Modal>
    </>
  );
};

export default OtherDocUpload;
