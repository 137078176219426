import React, { FC, useState } from "react";
import { FaFile } from "react-icons/fa";
import Typography from "../../../../component/semantic/Typography";
import deleteDoc from "../../../../assets/icons/newCase/deleteDoc.svg";
import { stagingUrl } from "../../../../helpers/constants/defaultUrls";
import { useAppDispatch } from "../../../../hooks/redux_toolkit";
import { removeLeadDocThunk } from "../../../../store/thunk/NewCase.thunk";
import { toast } from "react-toastify";
import Spinner from "../../../../component/common/Spinner";

interface ShowFileProps {
  fileArr: any;
  setFileArr: any;
  docName: string;
  createdLeadInfo: any;
}

const ShowFile: FC<ShowFileProps> = ({ fileArr, setFileArr, docName, createdLeadInfo }) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState("");

  const removeFileHandler = (i: number, name: string) => {
    setLoading(name);
    dispatch(removeLeadDocThunk({ id: createdLeadInfo?._id, file: name }))
      .unwrap()
      .then((data: any) => {
        if (data?.success) {
          toast.success(data?.message);
          const temp: any = { ...fileArr };
          temp[docName].splice(i, 1);
          setFileArr(temp);
          setLoading("");
        }
      });
  };

  let url: string;
  if (process.env.REACT_APP_ENV !== "prod") url = stagingUrl + "lead_docs/" + createdLeadInfo?._id;
  else url = "https://insurance-samadhan-docs.s3.ap-south-1.amazonaws.com/upload/" + "lead_docs/" + createdLeadInfo?._id;

  return (
    <span className=" items-center mt-2 flex flex-wrap justify-between gap-2">
      {fileArr &&
        docName &&
        fileArr[docName]?.map((obj: any, i: number) => {
          return (
            <div key={i} className=" flex  w-full">
              <div onClick={() => window.open(url + "/" + obj)} className="text-gray-700 cursor-pointer bg-uploadTheme flex px-4 rounded-lg py-1 gap-1 items-center">
                <FaFile className="text-gray-400 opacity-50" />
                <Typography className="w-48 truncate">{obj?.name || obj}</Typography>
              </div>
              {loading === obj ? <Spinner className="text-xl -mt-3 ml-2" /> : <img src={deleteDoc} onClick={() => removeFileHandler(i, obj)} className="ml-2 cursor-pointer" />}
            </div>
          );
        })}
    </span>
  );
};

export default ShowFile;
