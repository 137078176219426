import { NavLink, useLocation } from "react-router-dom";
import Logo from "../../assets/images/partnerLogo.svg";
import { routesFooter, routesMain } from "../../helpers/constants/sidebarList";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../hooks/redux_toolkit";
import checkAuthorizationService from "../../services/CheckAuthorization.service";
import ShareSamadhanModal from "./ShareSamadhan.modal";

interface SidebarProps {
  children: React.ReactNode;
  showSidebar: boolean;
}
const secondSection = ["/profile", "/changePassword", "/referral", "/chat", "/gallery", "/faq"];

const Sidebar: React.FC<SidebarProps> = ({ children, showSidebar }) => {
  const location = useLocation();
  useEffect(() => {
    const element = document.getElementById("sidebar");
    if (element && secondSection.includes(location.pathname)) {
      element.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    }
  }, [location]);

  const loginData = useAppSelector((state) => state.rootReducer.login);

  const [showShareModal, setShowShareModal] = useState(false);

  return (
    <>
      <div className={`${showSidebar ? " z-10 top-16 shadow-2xl" : "-translate-x-full lg:translate-x-0 top-0"}  bg-white w-[17.5rem] min-h-screen h-full top-0 fixed transition-transform rounded-none border-none drop-shadow-sm text-customgray`}>
        <div className="p-3 pt-4">
          <img src={Logo} alt="Partner Logo" />
        </div>
        <div id="sidebar" className="overflow-scroll h-full" style={{ scrollbarWidth: "none" }}>
          <div className="mt-8">
            {routesMain.map(
              (obj, i) =>
                (obj.renderToAll || checkAuthorizationService(loginData.roles, obj.authorizationRequired || [])) && (
                  <NavLink key={i} to={obj.route} className={({ isActive }) => `flex pl-2 cursor-pointer py-2 items-center hover:bg-[#fff9f3] hover:text-polyfixTheme ${isActive && "bg-[#fff9f3] text-polyfixTheme border-r-4 border-r-polyfixTheme"} icon mx-3 rounded`}>
                    <img src={location.pathname == obj.route ? obj.activeIcon : obj.icon} alt={obj.name} />
                    <p className="pt-1 pl-3 font-bold">{obj.name}</p>
                  </NavLink>
                )
            )}
          </div>
          <hr className="border-t border-gray-300 mx-4 my-3" />
          <div>
            {routesFooter.map((obj, i) => {
              if (obj.renderToAll || checkAuthorizationService(loginData.roles, obj.authorizationRequired || [])) {
                if (obj.name === "Visit Share Samadhan") {
                  return (
                    <div onClick={() => setShowShareModal(!showShareModal)} key={i} className="flex pl-2 cursor-pointer py-2 items-center hover:bg-[#fff9f3] hover:text-polyfixTheme icon mx-3 rounded hover:border-r-4 hover:border-r-polyfixTheme">
                      {" "}
                      {location.pathname == obj.route ? obj.activeIcon : obj.icon}
                      <p className="pt-1 pl-3 text-sm font-bold">{obj.name}</p>
                    </div>
                  );
                }
                return (
                  <NavLink key={i} to={{ pathname: obj.route }} className={({ isActive }) => `flex pl-2 cursor-pointer py-2 items-center hover:bg-[#fff9f3] hover:text-polyfixTheme ${isActive && "bg-[#fff9f3] text-polyfixTheme border-r-4 border-r-polyfixTheme"} icon mx-3 rounded `}>
                    {location.pathname == obj.route ? obj.activeIcon : obj.icon}
                    <p className="pt-1 pl-3 text-sm font-bold">{obj.name}</p>
                  </NavLink>
                );
              }
            })}
          </div>
          <hr className="border-t border-gray-300 mx-4 my-3" />
          <footer className="mb-20 pl-2">
            <p className="font-bold">Polifyx Partner Dashboard</p>
            <p className="text-sm">
              © 2024 All Rights Reserved
              <br />
              Developed by Insurance Samadhan
            </p>
          </footer>
        </div>
      </div>

      <main className={`ml-0 lg:ml-[19rem] mr:0 lg:mx-4 pt-24 `}>
        <ShareSamadhanModal setShowModal={setShowShareModal} showModal={showShareModal} />
        {children}
      </main>
    </>
  );
};

export default Sidebar;
