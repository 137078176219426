import React, { useEffect, useState } from "react";
import Container from "../../component/common/Container";
import Typography from "../../component/semantic/Typography";
import ButtonOutline from "../../component/semantic/Button";
import { PERMISSIONS, leadStatusConstant } from "../../helpers/constants/constants";
import { useAppDispatch, useAppSelector } from "../../hooks/redux_toolkit";
import { getAllLeadThunk, searchLeadCustomerThunk, searchLeadPartnerThunk } from "../../store/thunk/AllLead.thunk";
import ActionTable from "../../component/table/ActionTable";
import { leadHeading, leadKeys, sortKeysLead } from "../../component/table/TableConstants";
import Pagination from "../../component/common/Pagination";
import { getCurrentUser } from "../../helpers/currentUser";
import Spinner from "../../component/common/Spinner";
import emptyLead from "../../assets/icons/lead/emptyLead.svg";
import statusHistoryImage from "../../assets/icons/lead/statusHistory.svg";
import addCommentImage from "../../assets/icons/lead/addComment.svg";
import { actionModalTypes } from "../../helpers/constants/interfaceTypes";
import StatusHistory from "./components/dialogs/StatusHistory";
import AddComment from "./components/dialogs/AddComment";
import { IoIosArrowDown } from "react-icons/io";
import InputIcon from "../../component/semantic/InputIconMob";
import UploadDoc from "../../assets/icons/lead/uploadDoc.svg";
import assignLead from "../../assets/icons/lead/assignLead.svg";
import assignLeadExpert from "../../assets/icons/lead/assignLeadExpert.svg";
import UploadDocs from "./components/dialogs/UploadDoc";
import AssignLeadExecutive from "./components/dialogs/AssignLeadExecutive";
import AssignLeadExpert from "./components/dialogs/AssignLeadExpert";
import { setSortedDataLead } from "../../store/slices/AllLeadSlice";
import InputIconClear from "../allComplaints/component/InputIcon";
import checkAuthorizationService from "../../services/CheckAuthorization.service";
import AddCommentsAdmin from "./components/dialogs/AddCommentAdmin";

const Lead = () => {
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [showStatusHistory, setShowStatusHistory] = useState(false);
  const [showAddCommentModal, setShowAddCommentModal] = useState(false);
  const [docModal, setDocModal] = useState(false);
  const [showAssignLead, setShowAssignLead] = useState(false);
  const [showAssignLeadExpert, setShowAssignLeadExpert] = useState(false);
  const [selectedLead, setSelectedLead] = useState<any>();
  const [showStatus, setShowStatus] = useState(false);
  const [partnerSearchInput, setPartnerSearchInput] = useState("");
  const [customerSearchInput, setCustomerSearchInput] = useState("");
  const [showCommentAdmin, setShowCommentAdmin] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const dispatch = useAppDispatch();
  const currentUser = getCurrentUser();

  useEffect(() => {
    if (customerSearchInput) {
      dispatch(searchLeadCustomerThunk({ search: customerSearchInput, currentPage, pageSize, id: currentUser._id, userType: currentUser?.userType }));
    } else if (partnerSearchInput) {
      dispatch(searchLeadPartnerThunk({ search: partnerSearchInput, currentPage, pageSize }));
    } else {
      if (selectedStatus === "All") dispatch(getAllLeadThunk({ id: currentUser._id, pageSize, currentPage }));
      else dispatch(getAllLeadThunk({ id: currentUser._id, pageSize, currentPage, status: selectedStatus }));
    }
  }, [currentPage, selectedStatus]);

  const leadData = useAppSelector((state) => state.rootReducer.allLead);
  const loginData = useAppSelector((state) => state.rootReducer.login);

  const searchHandlerCustomer = () => {
    if (customerSearchInput) dispatch(searchLeadCustomerThunk({ search: customerSearchInput, currentPage: 1, pageSize, id: currentUser._id, userType: currentUser?.userType }));
    setPartnerSearchInput("");
  };

  const searchHandlerPartner = () => {
    if (partnerSearchInput) dispatch(searchLeadPartnerThunk({ search: partnerSearchInput, currentPage: 1, pageSize }));
    setCustomerSearchInput("");
  };

  const clearFilterHandler = () => {
    setCustomerSearchInput("");
    setPartnerSearchInput("");
    if (selectedStatus === "All") dispatch(getAllLeadThunk({ id: currentUser._id, pageSize, currentPage }));
    else dispatch(getAllLeadThunk({ id: currentUser._id, pageSize, currentPage, status: selectedStatus }));
  };

  const refreshHandler = () => {
    if (selectedStatus === "All") dispatch(getAllLeadThunk({ id: currentUser._id, pageSize, currentPage }));
    else dispatch(getAllLeadThunk({ id: currentUser._id, pageSize, currentPage, status: selectedStatus }));
  };

  const actions: actionModalTypes = [
    { image: <img src={addCommentImage} className="cursor-pointer" />, src: "LEAD", isAuthorizedRoute: checkAuthorizationService(loginData.roles, [PERMISSIONS.PARTNER, PERMISSIONS.ASSOCIATE, PERMISSIONS.ADVISOR]), tooltip: "Add Comment", onClickFunction: () => setShowAddCommentModal(true) },
    { image: <img src={addCommentImage} className="cursor-pointer" />, src: "LEAD", isAuthorizedRoute: checkAuthorizationService(loginData.roles, [PERMISSIONS.ADMIN]), tooltip: "Add Comment", onClickFunction: () => setShowCommentAdmin(true) },
    { image: <img src={statusHistoryImage} className="cursor-pointer" />, src: "LEAD", isAuthorizedRoute: checkAuthorizationService(loginData.roles, [PERMISSIONS.ADMIN, PERMISSIONS.PARTNER, PERMISSIONS.ASSOCIATE, PERMISSIONS.ADVISOR]), tooltip: "Status History", onClickFunction: () => setShowStatusHistory(true) },
    { image: <img src={UploadDoc} className="cursor-pointer" />, src: "LEAD", isAuthorizedRoute: checkAuthorizationService(loginData.roles, [PERMISSIONS.ADMIN, PERMISSIONS.PARTNER, PERMISSIONS.ASSOCIATE, PERMISSIONS.ADVISOR]), tooltip: "Upload Documents", onClickFunction: () => setDocModal(true) },
    { image: <img src={assignLead} className="cursor-pointer" />, src: "LEAD", isAuthorizedRoute: checkAuthorizationService(loginData.roles, [PERMISSIONS.ADMIN]), tooltip: "Assign this Lead to", onClickFunction: () => setShowAssignLead(true) },
    { image: <img src={assignLeadExpert} className="cursor-pointer" />, src: "LEAD", isAuthorizedRoute: checkAuthorizationService(loginData.roles, [PERMISSIONS.ADMIN]), tooltip: "Assign this Lead to Expert", onClickFunction: () => setShowAssignLeadExpert(true) }
  ];

  return (
    <>
      <p className="font-extrabold text-xl block md:hidden px-2 mx-4 mb-4 ">All Leads</p>

      <Container>
        <form className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 w-full">
          <div className="hidden md:flex  justify-center items-end gap-2 w-full">
            <InputIconClear onKeyDown={(e) => e.key == "Enter" && searchHandlerCustomer()} value={customerSearchInput} label="Search for Customer" placeholder="Name/Mobile/Email" onChange={(e) => setCustomerSearchInput(e.target.value)} onClickFunction={clearFilterHandler} />
            <ButtonOutline type="button" className={`bg-polyfixTheme  w-full md:w-42`} title="Search" onClick={searchHandlerCustomer} />
          </div>
          <div className="hidden md:flex  justify-center items-end gap-2 w-full">
            <InputIconClear onKeyDown={(e) => e.key == "Enter" && searchHandlerPartner()} value={partnerSearchInput} label="Search for Agent, Associate, Partner" placeholder="Name/Mobile/Email" onChange={(e) => setPartnerSearchInput(e.target.value)} onClickFunction={clearFilterHandler} />
            <ButtonOutline type="button" className={`bg-polyfixTheme  w-full md:w-42`} title="Search" onClick={searchHandlerPartner} />
          </div>
          <div className="flex md:hidden w-full flex-col gap-1 ">
            <label className="font-semibold">Search for Customer</label>
            <InputIcon placeholder="Name/Mobile/Email" onClickFunction={() => searchHandlerCustomer()} value={customerSearchInput} onChange={(e) => setCustomerSearchInput(e.target.value)} />
          </div>
          <div className="flex md:hidden w-full flex-col gap-1 ">
            <label className="font-semibold">Search for Agent, Associate, Partner</label>
            <InputIcon placeholder="Name/Mobile/Email" onClickFunction={() => searchHandlerPartner()} value={partnerSearchInput} onChange={(e) => setPartnerSearchInput(e.target.value)} />
          </div>
        </form>
      </Container>

      <Container customClassName="mt-6">
        <div className={`flex gap-2 transition-all flex-wrap items-center ${showStatus ? "h-full pb-10 md:pb-0" : "h-28 md:h-full"}  md:h-full overflow-hidden relative`}>
          {leadStatusConstant.map((status: { label: string; value: string }, i: number) => (
            <p onClick={() => setSelectedStatus(status.value)} className={`border-2 px-5 py-2  cursor-pointer font-semibold text-center rounded-full ${selectedStatus === status.value && "text-[#086F3D] bg-[#cbf2df]"}`} key={i}>
              {status.label}
            </p>
          ))}
          <div onClick={() => setShowStatus(!showStatus)} className="absolute w-36 justify-center cursor-pointer md:hidden bottom-[-0.5rem] shadow-all left-1/2 transform -translate-x-1/2  border border-polyfixTheme flex items-center p-3 bg-[#fff9f3] rounded-md gap-2">
            <Typography className="text-polyfixTheme">View {showStatus ? "Less" : "More"}</Typography>
            <IoIosArrowDown className={`text-gray-400 transition-all text-lg ${showStatus ? "rotate-180" : "rotate-0"}`} />
          </div>
        </div>
      </Container>

      <Container customClassName="mt-6 ">
        <Typography variant="h1">Leads</Typography>
        <Typography className="text-gray-600 mb-6">List of all cases provided by you and their status's. </Typography>
        {leadData.loading ? (
          <Spinner />
        ) : leadData.leadData?.data && leadData.leadData.data?.data.length == 0 ? (
          <div className="h-72 flex flex-col justify-center gap-3 items-center">
            <img src={emptyLead} />
            <Typography variant="h3" className="text-[#666666]">
              No data available for this status
            </Typography>
          </div>
        ) : (
          <>
            <ActionTable tableHeading={leadHeading} tableKeys={leadKeys} tableData={leadData.leadData.data?.data} actions={actions} selectedRow={setSelectedLead} sortedDataAction={setSortedDataLead} sortKey={sortKeysLead} />
            <Pagination itemsPerPage={pageSize} totalItems={leadData.leadData.data?.count || 0} setCurrentPage={setCurrentPage} currentPage={currentPage} />
          </>
        )}
        <StatusHistory showStatusHistory={showStatusHistory} setShowStatusHistory={setShowStatusHistory} selectedLead={selectedLead} />
        <AddComment showAddCommentModal={showAddCommentModal} setShowAddCommentModal={setShowAddCommentModal} selectedLead={selectedLead} />
        {showCommentAdmin && <AddCommentsAdmin addComments={showCommentAdmin} setAddComments={setShowCommentAdmin} selectedLead={selectedLead} />}
        <UploadDocs showModal={docModal} setShowModal={setDocModal} setSelectedLead={setSelectedLead} createdLeadInfo={selectedLead} />
        <AssignLeadExecutive showModal={showAssignLead} setShowLeadModal={setShowAssignLead} leadData={selectedLead} refreshHandler={refreshHandler} />
        <AssignLeadExpert showModal={showAssignLeadExpert} setShowLeadModal={setShowAssignLeadExpert} leadData={selectedLead} refreshHandler={refreshHandler} />
      </Container>
    </>
  );
};

export default Lead;
