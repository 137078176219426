import React from "react";
import { FiCheckCircle } from "react-icons/fi";

const SuccessScreen = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="text-center p-10 bg-white shadow-2xl rounded-3xl max-w-xl border-t-8 border-green-500">
        <FiCheckCircle className="w-20 h-20 text-green-500 mx-auto mb-6" />
        <h1 className="text-5xl font-extrabold text-green-600 mb-4">Success!</h1>
        <p className="text-xl text-gray-700 mb-6">Your lead has been created successfully.</p>
      </div>
    </div>
  );
};

export default SuccessScreen;
