import React, { useEffect, useState } from "react";
import FormInput from "../../../component/semantic/FormInput";
import Select from "react-select";
import { polyfixSelect } from "../../../helpers/constants/styles";
import { reportDateTypeComplaint, contractSignedArr } from "../../../helpers/constants/constants";
import Typography from "../../../component/semantic/Typography";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_toolkit";
import { getDropdownThunk } from "../../../store/thunk/Common.thunk";

interface ReportProps {
  formProps: any;
}

const UserReport: React.FC<ReportProps> = ({ formProps }) => {
  const [userListArr, setUserListArr] = useState<any>([]);
  const userList = useAppSelector((state) => state.rootReducer.common.userDropdownList);
  const [associateListArr, setAssociateListArr] = useState([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userList.data) setUserListArr(userList.data);
  }, [userList]);

  const handlePartnerChange = (option: any) => {
    formProps.setFieldValue("userId", option._id);
    dispatch(getDropdownThunk({ userType: "Vendor", hierarchicalLevel: 1, parentId: option._id }))
      .unwrap()
      .then((res) => {
        if (res?.success) {
          setAssociateListArr(res?.data);
          formProps.setFieldValue("childId", undefined);
          formProps.setFieldValue("grandChildId", undefined);
        }
      });
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      <FormInput name="startDate" max="true" type="date" label="Start Date" formProps={formProps} />
      <FormInput name="endDate" min={formProps.values.startDate} type="date" label="End Date" formProps={formProps} />
      <span>
        <Typography className="font-normal mb-1">Select Partner</Typography>
        <Select options={[{ _id: "all", name: "All" }, ...userListArr]} getOptionLabel={(option) => option.name} getOptionValue={(option) => option._id} className="w-full " styles={polyfixSelect} name="userId" onChange={handlePartnerChange} placeholder="Select Partner" />
      </span>

      {associateListArr.length > 0 && (
        <span>
          <Typography className="font-normal mb-1">Select Associate</Typography>
          <Select options={[{ _id: "all", name: "All" }, ...associateListArr]} getOptionLabel={(option) => option.name} getOptionValue={(option) => option._id} className="w-full " styles={polyfixSelect} name="childId" onChange={(option: any) => formProps.setFieldValue("childId", option._id)} placeholder="Select Associate" />
        </span>
      )}
      <span>
        <Typography className="font-normal mb-1">Contract Agreed</Typography>
        <Select
          options={contractSignedArr}
          placeholder="Select"
          styles={polyfixSelect}
          name="isAgreed"
          onChange={(option: any) => {
            formProps.setFieldValue("isAgreed", option.value == "Yes" ? true : false);
          }}
          isSearchable={false}
        />
      </span>
      <span>
        <Typography className="font-normal mb-1">Select Date Type</Typography>
        <Select
          options={reportDateTypeComplaint}
          name="type"
          onChange={(option) => {
            formProps.setFieldValue("type", option?.value);
          }}
          placeholder="Select Date Type"
          styles={polyfixSelect}
          isSearchable={false}
        />
      </span>
    </div>
  );
};

export default UserReport;
