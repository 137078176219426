import React, { FC, useState } from "react";
import Modal from "../../../component/common/Modal";
import CloseIcon from "../../../assets/icons/gallery/close.svg";
import Typography from "../../../component/semantic/Typography";
import ButtonOutline from "../../../component/semantic/Button";
import FileUploadDoc from "../../../component/common/FileUploadDoc";
import ShowFile from "./ShowFile";
import Divide from "../../../component/semantic/Divide";
import { useAppDispatch } from "../../../hooks/redux_toolkit";
import { uploadLeadDocThunk } from "../../../store/thunk/NewCase.thunk";
import { docDynamicField } from "../../../helpers/constants/constants";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

interface UploadDocsProps {
  showModal: boolean;
  setShowModal: any;
  createdLeadInfo: any;
  setCreatedLeadInfo: any;
  src: "newCase" | "createLead";
}

const UploadDocs: FC<UploadDocsProps> = ({ showModal, setShowModal, createdLeadInfo, src }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const initialGeneral = {
    policyBook: [],
    rejectionLetter: [],
    claimBill: [],
    surveyorReport: [],
    settlementLetter: [],
    communicationCompany: [],
    callRecording: [],
    deathCertificateGi: [],
    dischargeSummGi: [],
    FIRGi: [],
    postMortemReportGi: [],
    drReportGi: []
  };

  const initialHealth = {
    dischargeSummary: [],
    rejectionLetter: [],
    claimBill: [],
    policyDocHi: [],
    settlementLetterHi: [],
    communicationCompanyHi: [],
    callRecordingHi: [],
    previousPolicyHi: [],
    drCertificateHi: [],
    RT_PCRTestHi: [],
    DeathCertificateHi: [],
    FIRHi: [],
    PostMortemReportHi: []
  };

  const initialLife = {
    policySchedule: [],
    proofOfRecording: [],
    rejectionLetterLi: [],
    claimFormLi: [],
    communicationCompanyLi: [],
    callRecordingLi: [],
    dischargeSummLi: [],
    drReportLi: [],
    deathCertificate: [],
    FIR: [],
    postMortemReport: []
  };

  const [generalDoc, setGeneralDoc] = useState<any>(initialGeneral);

  const [healthDoc, setHealthDoc] = useState<any>(initialHealth);

  const [lifeDoc, setLifeDoc] = useState<any>(initialLife);

  const uploadHandler = (file: any, field: string, label: string) => {
    const fileName = label + "_" + new Date().getTime();

    const formData = new FormData();
    formData.append(field, file, fileName);
    formData.append("docCreationSrc", "partner-web");
    dispatch(uploadLeadDocThunk({ leadId: createdLeadInfo?.leadId, formData }))
      .unwrap()
      .then((data: any) => {
        if (data.status == 200) toast.success(data.msg);
      });
  };

  // const downloadDocHandler = () => {
  //   dispatch(downloadLeadDocThunk(createdLeadInfo?.leadId))
  //     .unwrap()
  //     .then((data) => {
  //       if (data && data instanceof Blob) {
  //         const downloadURL = window.URL.createObjectURL(data);
  //         const link = document.createElement("a");
  //         link.id = "all-docs";
  //         link.href = downloadURL;
  //         link.download = `allDocs.zip`;
  //         link.click();
  //       } else {
  //         // eslint-disable-next-line
  //         console.error("Invalid data object for createObjectURL:", data);
  //       }
  //     });
  // };

  const docFields = docDynamicField(createdLeadInfo?.complaintInfo?._id);

  const submitHandler = () => {
    setGeneralDoc(initialGeneral);
    setHealthDoc(initialHealth);
    setLifeDoc(initialLife);
    setShowModal(false);
    if (src === "createLead") navigate("/success-lead");
  };

  const closeModalHandler = () => {
    if (src === "createLead") navigate("/success-lead");
    setShowModal(false);
  };

  return (
    <Modal showModal={showModal} onClose={closeModalHandler} className={` w-[50%] py-10 px-8 relative `}>
      <img src={CloseIcon} className="absolute text-3xl text-gray-400 right-[-15px] top-[-20px] cursor-pointer" onClick={closeModalHandler} />
      <header>
        <Typography variant="h1">Upload Documents</Typography>
        <span className="flex flex-col lg:flex-row w-full gap-2 justify-between items-center">
          <Typography className="text-lg font-bold w-full lg:w-[60%]">Upload necessary documents for the lead so that our experts can evaluate your case.</Typography>
          {/* <div className="flex">
            <ButtonOutline title="Download All Docs" className="hover:bg-black hover:text-white w-full " onClick={downloadDocHandler} icon={<img src={DownloadDoc} />} />
          </div> */}
        </span>
      </header>
      <div className="w-full md:w-[80%] pt-2 h-80 space-y-1 overflow-y-scroll custom-polifyx-scrollbar mt-6 ">
        {createdLeadInfo &&
          docFields[createdLeadInfo?.policyInfo?.name]?.map((obj: any) => {
            return (
              <>
                <Typography>{obj.label}</Typography>
                {createdLeadInfo?.policyInfo?.name == "General Insurance" && obj.name && <FileUploadDoc id={obj.name} setSelectedFiles={setGeneralDoc} apiFunction={(file: any) => uploadHandler(file, obj.name, obj.fileName)} />}
                {createdLeadInfo?.policyInfo?.name == "Health Insurance" && obj.name && <FileUploadDoc id={obj.name} setSelectedFiles={setHealthDoc} apiFunction={(file: any) => uploadHandler(file, obj.name, obj.fileName)} />}
                {createdLeadInfo?.policyInfo?.name == "Life Insurance" && obj.name && <FileUploadDoc id={obj.name} setSelectedFiles={setLifeDoc} apiFunction={(file: any) => uploadHandler(file, obj.name, obj.fileName)} />}

                {createdLeadInfo?.policyInfo?.name == "General Insurance" && <ShowFile fileArr={generalDoc} docName={obj.name} setFileArr={setGeneralDoc} />}
                {createdLeadInfo?.policyInfo?.name == "Health Insurance" && <ShowFile fileArr={healthDoc} docName={obj.name} setFileArr={setHealthDoc} />}
                {createdLeadInfo?.policyInfo?.name == "Life Insurance" && <ShowFile fileArr={lifeDoc} docName={obj.name} setFileArr={setLifeDoc} />}
              </>
            );
          })}
      </div>
      <Divide className="my-3 -mx-8 " />
      <div className="block md:flex flex-row-reverse">
        <ButtonOutline className={`bg-polyfixTheme  w-full `} title="Submit Case" type="submit" onClick={submitHandler} />
      </div>
    </Modal>
  );
};

export default UploadDocs;
