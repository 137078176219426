import { createSlice } from "@reduxjs/toolkit";
import { getPayoutMarkingList, markPaidPayoutMarking } from "../thunk/PayoutMarking";
import { toast } from "react-toastify";
interface ApiState {
  data: any;
  loading: "idle" | "pending" | "fulfilled" | "rejected";
  loadingTableData: boolean;
  error: string | null;
  navigationUrl: string;
}

const initialState: ApiState = {
  data: [],
  loading: "idle",
  loadingTableData: false,
  error: null,
  navigationUrl: ""
};

const payoutMarking = createSlice({
  name: "payoutMarking",
  initialState,
  reducers: {
    emptyNavigationUrl: (state) => {
      state.navigationUrl = "";
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPayoutMarkingList.pending, (state) => {
        state.loadingTableData = true;
      })
      .addCase(getPayoutMarkingList.fulfilled, (state, action) => {
        if (action.payload?.data) {
          state.data = action.payload?.data;
        }
        state.loadingTableData = false;
        state.error = null;
      })
      .addCase(getPayoutMarkingList.rejected, (state, action) => {
        state.loadingTableData = false;
        state.error = action.error.message ?? "An error occurred";
      });

    builder
      .addCase(markPaidPayoutMarking.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(markPaidPayoutMarking.fulfilled, (state, action) => {
        if (action.payload?.data) {
          toast("Marked paid success");
        }
        state.loading = "fulfilled";
        state.error = null;
      })
      .addCase(markPaidPayoutMarking.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message ?? "An error occurred";
      });
  }
});

export const { emptyNavigationUrl } = payoutMarking.actions;

export default payoutMarking.reducer;
