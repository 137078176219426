import dayjs from "dayjs";

export const handleCopy = (texts: string[]) => {
  try {
    const textToCopy = texts.join("\n");
    navigator.clipboard.writeText(textToCopy);
  } catch (error) {
    // eslint-disable-next-line
    console.log(error);
  }
};

export const toInputDate = (data: string) => {
  if (!data) return;
  return dayjs(data).format("YYYY-MM-DD");
};
export const isEmpty = (obj: object) => {
  return Object.keys(obj).length === 0;
};

interface UserInfo {
  token: string;
}

export const checkUserAuthentication = (): boolean => {
  const tokenString = localStorage.getItem("userInfo");
  let userInfo: UserInfo | null = null;
  let token: string | undefined;

  if (tokenString) {
    userInfo = JSON.parse(tokenString);
    token = userInfo?.token;
  }

  return !!token;
};

export function formatNumberIntoLK(num: number) {
  if (num >= 100000) {
    const l = Math.floor(num / 100000);
    const k = Math.floor((num % 100000) / 1000);
    return k > 0 ? `${l}L ${k}K` : `${l}L`;
  } else if (num >= 1000) {
    return `${Math.floor(num / 1000)}K`;
  } else {
    return num.toLocaleString();
  }
}

export const downloadCSV = (downloadData: any, fileName: string) => {
  const blob = new Blob([downloadData], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", fileName + `.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export function convertToMidnightIST(timestamp: number) {
  const dateUTC = dayjs(timestamp).startOf("day").valueOf();
  return dateUTC;
}

export const logs = (data: string) => {
  // eslint-disable-next-line
  console.log(data);
};

export const hierarchicalLevelHandler = (data: number) => {
  if (data == 0) return "Partner";
  if (data == 1) return "Associate";
  if (data == 2) return "Advisor";
};

export const sortTableData = (tableData: any[], sortField: string, sortType: string) => {
  try {
    const getValue = (obj: any, path: string) => {
      return path.split(".").reduce((acc, part) => acc && acc[part], obj);
    };

    const newArray = [...tableData];
    return newArray.sort((a: any, b: any) => {
      const aValue = getValue(a, sortField);
      const bValue = getValue(b, sortField);

      if (aValue === undefined || bValue === undefined) {
        return 0;
      }

      // Determine if the values are numbers
      const isNumericA = !isNaN(aValue) && typeof aValue !== "object";
      const isNumericB = !isNaN(bValue) && typeof bValue !== "object";

      if (sortType === "asc") {
        if (isNumericA && isNumericB) {
          return aValue - bValue;
        }
        return String(aValue).toLowerCase() > String(bValue).toLowerCase() ? 1 : -1;
      } else if (sortType === "dsc") {
        if (isNumericA && isNumericB) {
          return bValue - aValue;
        }
        return String(aValue).toLowerCase() < String(bValue).toLowerCase() ? 1 : -1;
      } else {
        return 0;
      }
    });
  } catch (error) {
    console.log(error);
    return tableData;
  }
};

export function formatRupees(number: number) {
  const fotmattedAmount = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(number);

  return fotmattedAmount.replace("₹", "₹ ");
}
