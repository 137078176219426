import dayjs from "dayjs";
import React from "react";
import { hierarchicalLevelHandler } from "../../helpers/utils";
import calender from "../../assets/icons/gallery/calendar.svg";

interface TableProps {
  tableHeading: string[];
  tableKeys: string[];
  tableData: any[];
  currentPage?: number;
  pageSize?: number;
  className?: string;
}
const solvedStatus = ["Resolved", "Legal Recovery from Company", "Legal Notice Sent", "INVOICE RAISED", "Settled", "INVOICE PROCESSING", "PARTIAL SETTLED", "PARTIAL INVOICE RAISED"];

const SimpleTable: React.FC<TableProps> = ({ tableHeading, tableKeys, tableData, currentPage = 0, pageSize, className = "text-center" }) => {
  return (
    <div
      className="overflow-x-scroll w-full mr-6"
      style={{
        scrollbarWidth: "thin",
        scrollbarColor: "#D7DBEC transparent",
        msOverflowStyle: "none",
        overflowX: "scroll",
        overflowY: "hidden",
        borderRadius: "16px"
      }}
    >
      <table className="w-full">
        <thead >
          <tr className="border-b-2 whitespace-nowrap">
            {tableHeading.map((data: any, i: number) => (
              <th key={i} className="pr-1 md:pr-3 py-2 md:py-4 text-sm md:text-base text-start">
                {data}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={`text-gray-600 w-full  ${className}`}>
          {tableData && tableData.length > 0 ? (
            tableData.map((data, index) => (
              <tr key={index} className="border-b-2">
                {tableKeys.map((key, i) => {
                  if (i === 0) return null;

                  if (tableKeys[0] === "settledCase" && key === "serial") return <td>{index + 1}</td>;

                  if (tableKeys[0] === "report" && key === "serial" && pageSize) return <td>{currentPage * pageSize + index + 1}</td>;
                  if (tableKeys[0] === "user" && key === "serial" && pageSize) return <td>{currentPage * pageSize + index + 1}</td>;
                  if (tableKeys[0] === "activeUser" && key === "serial" && pageSize) return <td>{currentPage * pageSize + index + 1}</td>;

                  if (tableKeys[0] === "report" && key === "createdAt") return <td className="whitespace-nowrap">{dayjs(data[key] * 1000).format("MMM D, YYYY")}</td>;
                  if (tableKeys[0] === "report" && key === "statusHistory") return <td className="whitespace-nowrap">{dayjs(data?.statusHistory[0]?.date).format("MMM D, YYYY")}</td>;
                  if (tableKeys[0] === "activeUser" && key === "lastLogin") return <td className="whitespace-nowrap">{data[key] ? dayjs(data[key]).format("MMM D, YYYY") : "-"}</td>;
                  if (tableKeys[0] === "activeUser" && key === "lastActive") return <td className="whitespace-nowrap">{data[key] ? dayjs(data[key]).format("MMM D, YYYY") : "-"}</td>;
                  if (tableKeys[0] === "report" && key === "leadId") return <td className="whitespace-nowrap pl-4">{data[key]}</td>;
                  if (tableKeys[0] === "report" && key === "status") return <td className={`${solvedStatus.includes(data[key]) ? "text-green-500" : "text-orange-400"} font-bold`}>{data[key]}</td>;

                  if (tableKeys[0] === "user" && (key === "createdAt" || key === "agreedOn")) return <td className="whitespace-nowrap">{data[key] ? dayjs(data[key]).format("MMM D, YYYY") : "-"}</td>;
                  if (tableKeys[0] === "user" && key === "hierarchicalLevel") return <td className="whitespace-nowrap">{hierarchicalLevelHandler(data[key])}</td>;

                  if (tableKeys[0] === "gallery" && key == "name") return <td className="whitespace-nowrap">{data.title + "." + data.type.split("/")[1] || "-"}</td>;
                  if (tableKeys[0] === "gallery" && key == "createdAt")
                    return (
                      <td className=" text-end">
                        <div className="flex gap-3 justify-end text-end">
                          <img src={calender} />
                          <span>{dayjs(data["createdAt"]).format("ddd, D MMM, YYYY - h:mm A")}</span>
                        </div>
                      </td>
                    );

                  if (tableKeys[0] === "resolve" && key == "claimAmount")
                    return (
                      <td>
                        ₹ <span className="text-[#00AC4F] font-bold">{data[key]}</span>{" "}
                      </td>
                    );
                  if (tableKeys[0] === "resolve" && key == "resolutionDate") return <td>{data.resolutionDate ? dayjs(data.resolutionDate).format("DD/MM/YYYY") : "-"}</td>;
                  // if(tableKeys[0] === 'resolve' && key =='commission') return <td>₹ <span className='text-[#00AC4F] font-bold'>{data[key]}</span></td>

                  let cellValue = data[key];

                  if (key.includes(".")) {
                    const nestedKeys = key.split(".");
                    let nestedData = data;
                    for (const nestedKey of nestedKeys) {
                      nestedData = nestedData[nestedKey];
                      if ([undefined, null].includes(nestedData)) {
                        cellValue = "-";
                        break;
                      } else {
                        cellValue = nestedData;
                      }
                    }
                  }
                  return (
                    <td key={i} className="px-6 py-4 ">
                      {cellValue || "-"}
                    </td>
                  );
                })}
              </tr>
            ))
          ) : (
            <td colSpan={tableHeading.length} className="text-center text-xl pt-2">
              No data found
            </td>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default SimpleTable;
