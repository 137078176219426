import React from "react";

export const PayoutAction = ({ index, data, handleMarkPayoutMarking }: { index: number; data: any; handleMarkPayoutMarking: any; selectedRow: any }) => {
  return (
    <td className="relative" key={index}>
      <div className="flex justify-center">
        {data?.isPayoutDone ? (
          <p className="bg-[#D9F4EE]  text-[#1BA785] rounded-md font-bold px-2 py-1">Payout Done</p>
        ) : (
          <p onClick={() => handleMarkPayoutMarking(data)} className="bg-[#FAAA5233] cursor-pointer  text-[#FAAA52] rounded-md font-bold px-2 py-1">
            Mark Paid
          </p>
        )}
      </div>
    </td>
  );
};

export default PayoutAction;
